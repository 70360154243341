<template>
  <div class="app-container">
    <van-tabs v-model="query.status" sticky @change="search" class="tabs">
      <van-tab title="未到店" :name="1"></van-tab>
      <van-tab title="已到店" :name="2"></van-tab>
    </van-tabs>
    <van-cell-group class="block" style="padding-top: 45px">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell
          center
          :title="item.title"
          :label="item.desc"
          v-for="(item, index) in dataList"
          :key="index"
          @click="showQrcode(item)"
        >
          <template #label>
            <div style="width: 100%">
              <strong>参与时间：</strong> <span>{{ item.created_at }}</span>
              <br />
              <strong>到店时间：</strong> <span>{{ item.confirm_at }}</span>
            </div>
          </template>
          <van-button v-if="item.status === 1" size="small" type="info"
            >未到店</van-button
          >
          <van-button disabled v-if="item.status === 2" size="small" type="info"
            >已到店</van-button
          >
        </van-cell>
      </van-list>
    </van-cell-group>

    <van-dialog v-model="show" :title="title">
      <div class="qrcode-wrapper">
        <VueQr
          :correctLevel="3"
          :autoColor="false"
          colorDark="#313a90"
          :text="qrCodeUrl"
          :size="128"
          :margin="0"
          :logoMargin="3"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr
  },
  data () {
    return {
      loading: false,
      finished: false,
      dataList: [],
      total: 0,
      query: {
        status: 1,
        page: 1
      },
      show: false,
      title: '',
      qrCodeUrl: ''
    }
  },
  methods: {
    showQrcode (item) {
      if (item.status === 2) {
        return false
      }
      this.title = item.title
      this.qrCodeUrl = location.origin + '/#/my/sign?sys_id=' + item.sys_id
      this.show = true
    },
    search () {
      this.dataList = []
      this.query.page = 1
      this.loading = false
      this.finished = false
      // this.getDataList()
    },
    onLoad () {
      setTimeout(() => {
        this.getDataList()
        this.query.page++
      }, 500)
    },
    getDataList () {
      this.$axios.post('/wxc/article/join_lists', this.query).then(res => {
        res.data.list.map(item => {
          this.dataList.push(item)
        })
        this.total = res.data.total
        this.loading = false
        // 数据全部加载完成
        if (this.dataList.length >= res.data.total) {
        this.finished = true
      }
    })
  }
}
}
</script>
<style lang="scss" scoped>
.qrcode-wrapper {
  padding: 20px;
  text-align: center;
}
.tabs {
  position: fixed !important;
  width: 100%;
  z-index: 2;
}
/deep/ .van-cell__value {
  width: 70px;
  flex: initial;
}
.block {
  z-index: 1;
}
</style>